import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

(async () => {
    window.config = await (await fetch('/config/config.json')).json();
    const App = (await import('./App')).default;
    ReactDOM.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        document.getElementById('root')
);
    })()